<script>
import Layout from "../../../layouts/main.vue";
import PageHeader from "@/components/page-header";
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import { mapActions } from "vuex";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";


export default {
  setup() {
    return { v$: useVuelidate() };
  },
  data() {
    return {
      title: "Add",
      scategory: {
        service_categories_id: null,
        service_categories_name: null,
        service_categories_description: " ",
      },
      isSubmitted: false,
      editor: ClassicEditor,
      editorData: "",
      faqeditorData: "",
      content: "<h1>Some initial content</h1>",
      plugins: [
        "advlist autolink link image lists charmap preview hr anchor pagebreak spellchecker",
        "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime nonbreaking",
        "save table contextmenu directionality emoticons template paste textcolor",
      ],
      toolbar:
        "undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent fullpage | forecolor backcolor emoticons",
      options: {
        height: 300,
        style_formats: [
          { title: "Bold text", inline: "b" },
          { title: "Red text", inline: "span", styles: { color: "#ff0000" } },
          { title: "Red header", block: "h1", styles: { color: "#ff0000" } },
          { title: "Example 1", inline: "span", classes: "example1" },
          { title: "Example 2", inline: "span", classes: "example2" },
          { title: "Table styles" },
          { title: "Table row 1", selector: "tr", classes: "tablerow1" },
        ],
      },
      imageData: null,
    };
  },
  validations: {
    scategory: {
      service_categories_name: {
        required: helpers.withMessage("Name is required", required),
      },
      service_categories_description: {
        required: helpers.withMessage("Description is required", required),
      },
    },
  },
  computed: {
    dbimageSrc() {
      if (this.scategory.image && this.scategory.image.search("blob:") == -1) {
        return this.assetUrl + this.scategory.image;
      } else if (this.scategory.image) {
        return this.scategory.image;
      } else {
        return require('@/assets/images/no-image.png');
      }
    },
  },
  components: {
    Layout,
    PageHeader,
    ckeditor: CKEditor.component,
  },
  mounted() {
    // console.log("route", this.$route.name);
    if (this.$route.name == "EditServiceCategory" && this.$route.params.id) {

      
      // console.log("route", this.$route.name);
      this.getScategoryData(this.$route.params.id);
      this.title = "Update";
    }
  },
  methods: {
    ...mapActions({
      addScategory: "scategory/addScategory",
      getScategoryById: "scategory/getScategoryById",
    }),
    clear() {
      this.scategory = {
        service_categories_id: null,
        service_categories_name: null,
        service_categories_description: " ",
      };
    },
    save() {
      this.isSubmitted = true;
      this.v$.$touch();
      if (this.v$.$invalid) {
        return;
      }
      var formdata = new FormData();
      formdata.append("service_categories_id", this.scategory.service_categories_id);
      formdata.append("service_categories_name", this.scategory.service_categories_name);
      formdata.append("service_categories_description", this.scategory.service_categories_description);
      if (this.imageData) {
        formdata.append("image", this.imageData);
      }

      this.addScategory(formdata)
        .then((res) => {
          if (res.data) {
            this.isSubmitted = false;
            var msg = this.scategory.service_categories_id
              ? "Service Category Updated Successfully"
              : "Service Category Added Successfully";
            this.clear();
            this.$toast.open({
              message: msg,
              type: "success",
            });
            this.$router.push({ name: "ServiceCategory" });
          }
        })
        .catch((error) => {
          console.log(error);
          this.$toast.open({
            message: "Server Error",
            type: "error",
          });
        });
    },
    getScategoryData(id) {
      this.getScategoryById(id)
        .then((res) => {
          console.log("resss", res.data.service_categories);
          if (res.data.message == 'Service Category fetched successfully') {
            let scategory = res.data.service_categories;
            this.scategory = {
              service_categories_id: scategory.service_categories_id,
              service_categories_name: scategory.service_categories_name,
              service_categories_description: scategory.service_categories_description,
            };
          } else {
            this.$router.push({ name: "AddServiceCategory" });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    onFileChange(e) {
      const file = e.target.files[0];
      if (file) {
        if (
          file.type == "image/png" ||
          file.type == "image/jpg" ||
          file.type == "image/jpeg" ||
          file.type == "image/svg" ||
          file.type == "image/svg+xml"
        ) {
          this.imageData = file;
          this.imageUrl = URL.createObjectURL(file);
          this.service.image = this.imageUrl;
        } else {
          this.$refs.image.value = "";
          this.$toast.open({
              message: "Please enter valid image file.",
              type: "error",
            });
        }
      }
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <div class="row">
      <div class="col-xl-12">
        <div class="card">

          <!-- {{ getScategoryData }}-->

          <!-- {{ scategory }}        -->
              <div class="card-header align-items-center d-flex">
            <h4 class="card-title mb-0 flex-grow-1">{{ title }} Service Category</h4>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6 offset-md-3">
                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="service_categories_name" class="form-label">Service Category Name</label>
                  </div>
                  <div class="col-lg-9">
                    <input
                      type="text"
                      class="form-control"
                      id="service_categories_name"
                  
                      v-model="scategory.service_categories_name"
                      :class="{
                        'is-invalid': isSubmitted && v$.scategory.service_categories_name.$error,
                      }"
                    />
                    <div
                      v-for="(item, index) in v$.scategory.service_categories_name.$errors"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                  </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="service_categories_description" class="form-label"
                      >Service Category Description</label
                    >
                  </div>
                  <div class="col-lg-9">
                 

                    <ckeditor
                    v-model="scategory.service_categories_description"
                    :editor="editor"
                  ></ckeditor>
                </div>

                    <div
                      v-for="(item, index) in v$.scategory.service_categories_description.$errors"
                      :key="index"
                      class="invalid-feedback"
                    >
                      <span v-if="item.$message">{{ item.$message }}</span>
                    </div>
                </div>

                <div class="row mb-4">
                  <div class="col-lg-3">
                    <label for="country" class="form-label">{{ $t('service.image') }}</label>
                  </div>
                  <div class="col-lg-9">
                    <div class="image-root-div">
                      <input
                        type="file"
                        class="d-none"
                        ref="image"
                        @change="onFileChange($event)"
                      />
                      <div
                        class="image-block-edit-icon"
                        @click="$refs.image.click()"
                      >
                        <i class="bx bxs-pencil" aria-hidden="true"></i>
                      </div>
                      <img
                        class="image-block-inside"
                        v-if="imageData"
                        :src="imageUrl"
                      />
                      <img
                        class="image-block-inside"
                        v-else
                        :src="dbimageSrc"
                      />
                    </div>
                  </div>
                </div>
                </div>

                <div class="row">
                  <div class="col-lg-9 offset-lg-3">
                    <button
                      type="button"
                      class="btn btn-primary mx-3"
                      @click="save"
                    >
                    Save
                    </button>
                    <router-link :to="{ name: 'ServiceCategory' }" class="btn btn-secondary">
                    Cancel
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    
  </Layout>
</template>

<style scoped>
.image-root-div {
  position: relative;
  height: 90px;
  width: 80px;
}
.image-block-edit-icon {
  position: absolute;
  color: #11132d;
  left: 0;
  background: #adb5bd;
  font-size: 16px;
  border-radius: 50%;
  height: 25px;
  width: 25px;
  display: flex;
  padding-left: 6px;
  padding-top: 4px;
  top: -10px;
}

.image-block-inside {
  object-fit: cover;
  width: 100%;
  border-radius: 0.75rem;
  height: 100%;
  margin-left: 10px;
}
</style>
